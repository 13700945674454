<template>
  <section id="dashboard-ecommerce">
    <p v-if="isDevelopment()">
      {{ error }}
    </p>
    <ServerUnreachable
      v-if="error"
      :is-maintenance="error === 503"
      :retry-callback="reloadData"
    />
    <template
      v-else-if="data"
    >

      <b-row class="match-height">
        <!-- TODO: Future feature -->
        <!--        <b-col-->
        <!--          xl="4"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <ecommerce-medal :data="data.congratulations" />-->
        <!--        </b-col>-->

        <b-col
          xl="12"
          md="12"
        >
          <Transition name="slide-fade">
            <onboarding
              v-if="data.onboarding.show"
              :progress="data.onboarding.progress"
              @hide="data.onboarding.show = false"
            />
          </Transition>
        </b-col>

        <b-col
          xl="2"
          md="6"
        >
          <area-chart-card
            :statistic="mkFormatter(data.visitors.total)"
            color="primary"
            :statistic-title="`${$t('cards.visitorsCard.title')} (${$t('cards.visitorsCard.subtitle')})`"
            :labels="data.visitors.labels.map(label => this.$t(`week_days.${label}`))"
            :series-names="[$t('cards.visitorsCard.chart_series_name')]"
            :chart-data="data.visitors.series"
          />
        </b-col>
        <b-col
          xl="2"
          md="6"
        >
          <area-chart-card
            :statistic="mkFormatter(data.pageViews.total)"
            color="info"
            :statistic-title="`${$t('cards.pageViewsCard.title')} (${$t('cards.pageViewsCard.subtitle')})`"
            :labels="data.pageViews.labels.map(label => this.$t(`week_days.${label}`))"
            :series-names="[$t('cards.pageViewsCard.chart_series_name')]"
            :chart-data="data.pageViews.series"
          />
        </b-col>
        <b-col
          xl="6"
          md="6"
        >
          <ecommerce-statistics :data="data.statisticsItems" />
        </b-col>
        <b-col
          xl="2"
          md="6"
        >
          <current-plan-card :data="data.store.subscription || {}" />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col lg="4">
          <b-row class="match-height">
            <!--            &lt;!&ndash; Bar Chart - Orders &ndash;&gt;-->
            <b-col
              lg="12"
              md="6"
            >
              <ecommerce-order-chart :data="data.statisticsOrder" />
            </b-col>
            <!--/ Bar Chart - Orders -->
            <!--            <b-col-->
            <!--              lg="12"-->
            <!--              md="6"-->
            <!--              cols="6"-->
            <!--            >-->
            <!--              <area-chart-card-->
            <!--                  :statistic="mkFormatter(data.pageViews.total)"-->
            <!--                  color="info"-->
            <!--                  :statistic-title="`${$t('cards.pageViewsCard.title')} (${$t('cards.pageViewsCard.subtitle')})`"-->
            <!--                  :labels="data.pageViews.labels.map(label => this.$t(`week_days.${label}`))"-->
            <!--                  :series-names="[$t('cards.pageViewsCard.chart_series_name')]"-->
            <!--                  :chart-data="data.pageViews.series"-->
            <!--              />-->
            <!--            </b-col>-->
            <b-col
              lg="12"
              md="6"
            >

              <ecommerce-earnings-chart :data="data.earningsChart" />
            </b-col>
          </b-row>
        </b-col>

        <!-- Revenue Report Card -->
        <b-col lg="8">
          <ecommerce-revenue-report :data="data.revenue" />
        </b-col>
      <!--/ Revenue Report Card -->
      </b-row>

      <b-row class="match-height">
        <!--        &lt;!&ndash; Company Table Card &ndash;&gt;-->
        <!--        <b-col lg="8">-->
        <!--          <ecommerce-company-table :table-data="data.companyTable" />-->
        <!--        </b-col>-->
        <!--        &lt;!&ndash;/ Company Table Card &ndash;&gt;-->

        <!--        &lt;!&ndash; Developer Meetup Card &ndash;&gt;-->
        <!--        <b-col-->
        <!--          lg="4"-->
        <!--          md="6"-->
        <!--        >-->
        <!--          <ecommerce-meetup :data="data.meetup" />-->
        <!--        </b-col>-->
        <!--        &lt;!&ndash;/ Developer Meetup Card &ndash;&gt;-->

        <!--        &lt;!&ndash; Browser States Card &ndash;&gt;-->
        <!--        <b-col-->
        <!--          lg="4"-->
        <!--          md="6"-->
        <!--        >-->
        <!--          <ecommerce-order-states :data="data.orderStatesData" />-->
        <!--        </b-col>-->
        <!--        &lt;!&ndash;/ Browser States Card &ndash;&gt;-->
        <!-- Browser States Card -->
        <b-col
          lg="4"
          md="6"
        >
          <order-states-donut-chart :data="data.orderStatesData" />
        </b-col>
        <!--/ Browser States Card -->

        <!-- Goal Overview Card -->
        <b-col
          lg="4"
          md="6"
        >
          <ecommerce-goal-overview :data="data.goalOverview" />
        </b-col>
        <!--/ Goal Overview Card -->

        <!-- Transaction Card -->
        <b-col
          v-if="data.recentOrderData"
          lg="4"
          md="6"
        >
          <ecommerce-recent-orders :data="data.recentOrderData" />
        </b-col>
      <!--/ Transaction Card -->

        <!--        &lt;!&ndash; Transaction Card &ndash;&gt;-->
        <!--        <b-col-->
        <!--          v-if="data.transactionData"-->
        <!--          lg="4"-->
        <!--          md="6"-->
        <!--        >-->
        <!--          <ecommerce-transactions :data="data.transactionData" />-->
        <!--        </b-col>-->
        <!--      &lt;!&ndash;/ Transaction Card &ndash;&gt;-->
      </b-row>
    </template>

    <dashboard-loader
      v-else
    />
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { isDevelopment } from '@core/utils/utils'
import OrderStatesDonutChart from '@/views/pages/index/OrderStatesDonutChart.vue'
import AreaChartCard from '@/views/pages/index/AreaChartCard.vue'
import { mkFormatter } from '@core/utils/filter'
// import EcommerceMedal from './EcommerceMedal.vue'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'
// import useSession from '@/auth/jwt/useJwt'
import EcommerceRecentOrders from '@/views/pages/index/EcommerceRecentOrders.vue'
import { setStoreData } from '@/auth/utils'
import Onboarding from '@/views/pages/index/Onboarding.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import CurrentPlanCard from './CurrentPlanCard.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import DashboardLoader from '../../loaders/DashboardLoader.vue'

export default {
  components: {
    Onboarding,
    EcommerceRecentOrders,
    AreaChartCard,
    BRow,
    BCol,

    // EcommerceMedal,
    EcommerceStatistics,
    CurrentPlanCard,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceEarningsChart,
    EcommerceGoalOverview,
    DashboardLoader,
    OrderStatesDonutChart,
  },
  data() {
    return {
      data: null,
      error: null,
    }
  },
  created() {
    // data
    this.reloadData()
  },
  methods: {
    mkFormatter,
    isDevelopment,
    reloadData() {
      this.data = null
      this.error = null
      this.$http.get(`/${jwtDefaultConfig.DASHBOARD_ENDPOINT}/`)
        .then(response => {
          this.data = response.data.data

          // ? Your API will return name of logged in user or you might just directly get name of logged in user
          // ? This is just for demo purpose
          const userData = this.data.user
          this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username

          if (Object.hasOwn(this.data, 'store')) {
            setStoreData(this.data.store)

            this.$store.commit('storeConfig/UPDATE_URL_READY', this.data.store.is_url_ready)
            this.$store.commit('storeConfig/UPDATE_THEME_READY', this.data.store.is_theme_ready)
          }

          if (this.data.store.currency) {
            this.$store.commit('appConfig/SET_CURRENCY', this.data.store.currency)
          }
          if (this.data.store.arabic_country) {
            this.$store.commit('appConfig/SET_ARABIC_COUNTRY', this.data.store.arabic_country)
          }
        }).catch(error => {
          this.error = error?.response?.status || 500
          if (isDevelopment()) {
            console.error(error)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
